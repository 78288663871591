<mat-form-field class="mat-form-field-custom-date" appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [placeholder]="placeholder"
    [formControl]="formControl"
    [matDatepicker]="picker"
    appMaskDate
  />
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
