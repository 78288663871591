import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ChildcareInstitutionDialogModule } from '../childcare-institution-dialog/childcare-institution-dialog.module';
import { ChildcareInstitutionComponent } from './childcare-institution.component';

@NgModule({
  declarations: [ChildcareInstitutionComponent],
  exports: [ChildcareInstitutionComponent],
  imports: [
    ChildcareInstitutionDialogModule,
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class ChildcareInstitutionModule {}
