import { Pipe, PipeTransform } from '@angular/core';
import { format, parse } from 'date-fns';

@Pipe({ name: 'date_format' })
export class DateFormatPipe implements PipeTransform {

  transform(date: any): string {
    return format(parse(date, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
  }
}
