import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NgControl,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { InputDateAnswer } from '../../dynamic-form.classes';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputDateComponent,
    },
  ],
})
export class InputDateComponent
  implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<Date> {
  @Input()
  label: string;

  @Input()
  placeholder: string;

  startDate: Date = new Date();

  @Input()
  set value(value: Date) {
    this.formControl.setValue(value);
    this.stateChanges.next();
  }
  get value() {
    return this.formControl.value;
  }

  stateChanges = new Subject<void>();

  formControl: FormControl;

  id: string;
  focused: boolean;
  empty: boolean;
  shouldLabelFloat: boolean;
  required: boolean;
  disabled: boolean;
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;

  onChange = (value: any) => {};
  onTouched = () => {};

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fb: FormBuilder
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.formControl = new FormControl();
  }

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe((value) => {
      if (value) {
        this.onChange(new InputDateAnswer(value));
      } else {
        this.onChange(null);
      }
    });
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  writeValue(value: Date): void {
    this.value = value;
  }
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState?(isDisabled: boolean): void {}
  setDescribedByIds(ids: string[]): void {}
  onContainerClick(event: MouseEvent): void {}
}
