import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  Resolve,
  Router
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SimpleDialogComponent } from 'src/app/components/simple-dialog/simple-dialog.component';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';

@Injectable({ providedIn: 'root' })
export class DynamicFormsResolver implements Resolve<string> {
  constructor(
    public router: Router,
    private dynamicFormService: DynamicFormService,
    private dialog: MatDialog,
  ) {}

  resolve(): Observable<string> {
    const state = this.router.getCurrentNavigation().extras.state;
    return this.dynamicFormService
      .findByCategoryIdAndTypeId(state.categoryId, state.organizationTypeId)
      .pipe(
        map((document) => ({
          documentId: document.id,
          organizationTypeId: state.organizationTypeId,
          organizationId: state.organizationId,
          personId: state.personId,
        })),
        catchError((err) => {
          SimpleDialogComponent.open(this.dialog, {
            okButton: 'OK',
            title: 'Não foi possível carregar o formulário escolhido.',
            content: 'Tente novamente mais tarde ou acione o suporte no botão Ajuda.'
          });
          this.router.navigate(['/t']);
          return of(undefined);
        })
      );
  }
}
