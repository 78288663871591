import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, of } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { COMPANY_TYPE } from '../environments/environment';
import { SimpleDialogComponent } from './components/simple-dialog/simple-dialog.component';
import { AuthService } from './services/auth.service';
import { MenuService } from './services/menu.service';
import { SupportChatService } from './services/support-chat.service';
import { TitleService } from './services/title.service';
import { CustomLogMonitor } from './shared/custom-log-monitor';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  private title = 'enviei.me';
  private navigationEnd$;
  private updateDialog;
  public loading$ = new BehaviorSubject<boolean>(false);
  public renderChatButton = false;
  public companyType = COMPANY_TYPE;
  public menu: boolean;

  @ViewChild(RouterOutlet) public outlet: RouterOutlet;
  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(
    public supportChatService: SupportChatService,
    public authService: AuthService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private titleService: TitleService,
    private swUpdate: SwUpdate,
    private logger: NGXLogger,
    private dialog: MatDialog,
    private menuService: MenuService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry
  ) {
    // Send warn/error messages to Sentry
    this.logger.registerMonitor(new CustomLogMonitor());
    this.menuService.open
      .subscribe(() => this.sidenav.open());

    this.matIconRegistry.addSvgIcon(
      `stethoscope`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/stethoscope.svg')
    );
  }

  getTitle(state, parent): string[] {
    const data = [];
    if (parent?.snapshot?.data?.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  ngOnInit(): void {
    this.updateDetection();

    this.router.events.pipe(
      tap(event => {
        switch (true) {
          case event instanceof NavigationStart: {
            this.loading$.next(true);
            break;
          }
          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this.loading$.next(false);
            break;
          }
          default: {
            break;
          }
        }

      }),
      filter((event) => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.titleService.title = this.getTitle(this.router.routerState, this.router.routerState.root).join(' | ');
    });

    this.supportChatService.init();
  }

  ngAfterViewInit(): void {
    this.outlet.activateEvents.subscribe((component) => {
      // render chat button when child route is active (to decide color theme)
      this.renderChatButton = this.outlet.isActivated;
    });
  }

  updateDetection(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(
        tap((evt) => {
          console.log('Evt', evt.type);
        }),
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        })),
        map(() => this.router.url === '/'),
        mergeMap((homeScreen) => {
          if (homeScreen) {
            return of(true);
          } else if (!this.updateDialog) {
            this.updateDialog = SimpleDialogComponent.open(this.dialog, {
              title: 'Nova versão do app',
              content: 'Precisamos atualizar a página para acessar a versão mais nova do app',
              okButton: 'OK',
              disableClose: true
            }).afterClosed();
          }
          return this.updateDialog;
        }))
        .subscribe(() => {
          this.forceUpdate();
        });

      // Force reload if an error is detected
      this.swUpdate.unrecoverable.subscribe(() => {
        this.forceUpdate();
      });
    }
  }

  forceUpdate(): void {
    window.location.reload();
  }

  public menuEdit(): void {
    alert('menuEdit');
  }

  public signOut(): void {
    alert('Sign Out');
  }
}
