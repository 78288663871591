import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export const API_CHILDCARE_ENROLLMENTS_LIST = '/bff/v1/b2c/childcare-enrollments';
export const API_DOC_TYPES = `/v4/b2c/sick_notes_types`;
export const API_DOCUMENT_CHECK_IF_IS_PROCESSING = (shortId: string) => `/bff/v1/b2c/documents/${shortId}/processing?id_type=short`;
export const API_DOCUMENT_CREATE = '/bff/v1/b2c/documents';
export const API_DOCUMENT_GET_BY_SHORT_ID = (shortId: string) => `/bff/v1/b2c/documents/${shortId}?id_type=short`;
export const API_DOCUMENT_TYPES_LIST = '/bff/v1/b2c/document-types?visible_b2c=true&deleted=false';
export const API_DYNAMIC_FORMS_FIND_BY_CATEGORY_ID_AND_TYPE_ID = (categoryId: number, typeId: number) => `/bff/v1/b2c/dynamic-forms/category/${categoryId}/type/${typeId}`;
export const API_DYNAMIC_FORMS_NEXT_STATE = (id: string) => `/bff/v1/b2c/dynamic-forms/next-state/${id}`;
export const API_GET_SICK_NOTE = (shortId: string) => `/v4/b2c/sick_notes/${shortId}`;
export const API_MESSAGES_LIST = '/bff/v1/b2c/messages/';
export const API_PERSON_DEPENDENTS_LIST = '/bff/v1/b2c/person-dependents';
export const API_PERSON_TOKEN = `/v4/b2c/people/auth`;
export const API_PROFESSIONAL_TYPES = '/v4/professional_types';
export const API_PROFESSIONALS = (codeNumber: string, state: string) => `/v4/professionals/${codeNumber}/${state}`;
export const API_READ_QRCODE = `/v4/b2c/utils/sick_notes/qrcode`;
export const API_SAVE_PUSH_NOTIFICATION_TOKEN = (organizationId: number, personId: string) => `/bff/v1/b2c/organizations/${organizationId}/push-notifications/${personId}`;
export const API_CALLBACK_NOTIFICATION = '/bff/v1/b2c/notifications';
export const API_SEARCH_ICDS = `/v4/b2c/search/icds`;
export const API_SEND_SICK_NOTE_FILLED = `/v4/b2c/sick_notes/filled`;
export const API_SEND_SICK_NOTE_PENDING = `/v4/b2c/sick_notes/pending`;
export const API_SETTINGS = `/v4/b2c/settings`;
export const API_USER_HMAC = (app: string) => `/v3/utils/hmac/${app}`;
export const API_VACCINES = 'v3/utils/vaccines';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private httpClient: HttpClient) { }

  get http(): HttpClient {
    return this.httpClient;
  }
}
