<div data-test="content" class="container">
  <div class="padding-workaround">
    <div class="content">
      <mat-radio-group
        class="documents"
        aria-labelledby="documents type"
        [(ngModel)]="docTypeService.selected"
      >
        <mat-radio-button class="document" *ngFor="let document of documents" [value]="document">
          {{document.name}}
          <button
            matSuffix
            mat-button
            mat-icon-button
            class="document__tooltip"
            *ngIf="document.description"
            (click)="showTooltip($event, tooltip)"
          >
            <mat-icon
              color="accent"
              #tooltip="matTooltip"
              [matTooltip]="document.description">
              info
            </mat-icon>
          </button>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="button-container">
    <button
      mat-raised-button
      class="closecare-button primary-bg"
      [disabled]="!docTypeService.selected"
      (click)="next()">
      Próximo
    </button>
  </div>

<app-loading *ngIf="loading"></app-loading>

