import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TitleService } from 'src/app/services/title.service';
import { skipWhile, take } from 'rxjs/operators';
import { CompanyService } from 'src/app/services/company.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit, OnDestroy {

  logoUrl: string;
  subscription = new Subscription();

  constructor(
    public titleService: TitleService,
    private location: Location,
    private authService: AuthService,
    private companyService: CompanyService
  ) { }

  ngOnInit() {
    this.subscription.add(
      this.authService.user$
        .pipe(
          skipWhile(user => !!this.logoUrl || !user)
        )
        .subscribe(() => {
          this.subscription.add(
            this.companyService.getSettings()
              .subscribe(({ logo_url }) => {
                this.logoUrl = logo_url;
              })
          );
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  back(): void {
    this.location.back();
  }
}
