import {
  Component,
  Input,
  OnDestroy,
  Optional,
  Self
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NgControl,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { ChildcareInstitutionAnswer } from '../../../dynamic-form.classes';
import { ComponentType, RadioType } from '../../../entities/dynamic-form.entity';
import { ChildcareInstitutionDialogComponent } from '../childcare-institution-dialog/childcare-institution-dialog.component';

@Component({
  selector: 'app-childcare-institution',
  templateUrl: './childcare-institution.component.html',
  styleUrls: ['./childcare-institution.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: ChildcareInstitutionComponent,
    },
  ],
})
export class ChildcareInstitutionComponent
  implements OnDestroy, ControlValueAccessor, MatFormFieldControl<RadioType> {
  @Input()
  answers: any;

  @Input()
  currentComponent: ComponentType;

  @Input()
  set value(value: RadioType) {
    this.formControl.setValue(value);
    this.stateChanges.next();
  }
  get value() {
    return this.formControl.value;
  }

  stateChanges = new Subject<void>();
  formControl: FormControl;

  id: string;
  focused: boolean;
  empty: boolean;
  shouldLabelFloat: boolean;
  required: boolean;
  disabled: boolean;
  placeholder: string;
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;

  onChange = (value: any) => { };
  onTouched = () => { };

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fb: FormBuilder,
    public dialog: MatDialog,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formControl = new FormControl('');
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  openDialog() {
    const dialogRef = this.dialog.open(ChildcareInstitutionDialogComponent, {
      width: '90%',
      panelClass: 'childcare-institution-dialog',
      data: {
        personDependentId: this.answers.find((answer) => answer.field === 'dependent').value,
        title: this.currentComponent.label,
        button: this.currentComponent.innerButton
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onChange(new ChildcareInstitutionAnswer(result));
      }
    });
  }

  writeValue(value: RadioType): void {
    this.value = value;
  }
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState?(isDisabled: boolean): void { }
  setDescribedByIds(ids: string[]): void { }
  onContainerClick(event: MouseEvent): void { }
}
