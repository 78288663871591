import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Company } from 'src/app/services/company.service';

@Component({
  selector: 'app-logged-in-user',
  templateUrl: './logged-in-user.component.html',
  styleUrls: ['./logged-in-user.component.scss']
})
export class LoggedInUserComponent {
  showImage: boolean;

  @Input()
  username: string;

  @Input()
  company: Company;

  @Output()
  onLogout: EventEmitter<string> = new EventEmitter();

  @Output()
  onNext: EventEmitter<string> = new EventEmitter();

  constructor() {
    this.showImage = true;
  }

  onLogoImageLoadError() {
    this.showImage = false;
  }

  logout() {
    this.onLogout.emit('logout');
  }

  next() {
    this.onNext.emit('next');
  }
}
