import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { EMPTY, from, merge, Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { Company, CompanyService } from 'src/app/services/company.service';

@Injectable({
  providedIn: 'root'
})
export class HomeResolver {
  constructor(
    private router: Router,
    private companyService: CompanyService,
    private authService: AuthService,
  ) { }

  resolve(routeSnapshot: ActivatedRouteSnapshot): Observable<HomeData> {
    return this.authService.user$.pipe(
      take(1),
      mergeMap((user: firebase.User) => {
        if (user) {
          return from(user.getIdTokenResult()).pipe(
            mergeMap((tr) => {
              if (tr.claims.organization_id) {
                return this.companyService.getCompanyByOrganizationId(tr.claims.organization_id).pipe(
                  map((company: Company) => ({ user, company } as HomeData))
                );
              }
              return of({ user, company: null } as HomeData);
            })
          );
        }
        return of({ user: null, company: null } as HomeData);
      })
    );
  }
}

export class HomeData {
  user: firebase.User | null;
  company: Company | null;
}
