import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICDResponse } from '../models/icd';
import { ListResponse, Professional, ProfessionalType } from '../models/professional';
import { SickNoteBody } from '../models/sick-note';
import { VaccineType } from '../models/vaccine';
import { Send } from '../pages/chat/shared/models/chat.model';
import {
  API_PROFESSIONALS,
  API_PROFESSIONAL_TYPES,
  API_SEARCH_ICDS,
  API_VACCINES,
  ApiService
} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public allowanceHours: number;

  private $data: SickNoteBody = {};
  private $invalidICDs: Array<string>;

  constructor(private api: ApiService) {}

  public get data(): SickNoteBody {
    return this.$data;
  }

  public get invalidICDs(): Array<string> {
    return this.$invalidICDs;
  }

  public resetData(): void {
    this.$data = {};
  }

  public removeData(keys: Array<string> = []): void {
    keys.forEach((key: string) => delete this.$data[key]);
  }

  public buildData(sends: Send | Array<Send>): void {
    if (!Array.isArray(sends)) {
      return this.addData(sends);
    }

    sends.forEach((send: Send) => this.addData(send));
  }

  public validateICDs(): Observable<boolean> {
    const requests: Array<Observable<ICDResponse>> = [];
    const icds: Array<string> = this.$data.icds.split(', ');

    let params: HttpParams = new HttpParams();
    params = params.append('obj_per_page', '99');

    for (const icd of icds) {
      requests.push(this.api.http.get<ICDResponse>(API_SEARCH_ICDS, {
        params: params.append('query', icd)
      }));
    }

    return forkJoin(requests)
      .pipe(map((results: Array<ICDResponse>) => this.checkICDs(results, icds)));
  }

  public getProfessionals(): Observable<string> {
    return this.api.http.get(API_PROFESSIONALS(
      this.data.professional_id, this.data.professional_state
    )).pipe(map((professional: Professional) => professional.name));
  }

  public getVaccineTypes(): Observable<{[key: string]: string}> {
    return this.api.http.get(API_VACCINES)
      .pipe(
        map(({items}: ListResponse<VaccineType>) => items.reduce((obj, cur) => ({...obj, [cur.id]: cur.full_name}), {}))
      );
  }

  public getProfessionalTypes(): Observable<{[key: string]: string}> {
    return this.api.http.get<ListResponse<ProfessionalType>>(API_PROFESSIONAL_TYPES)
      .pipe(
        map(({items}: ListResponse<ProfessionalType>) => items.reduce((obj, cur) => ({...obj, [cur.code]: cur.label}), {}))
      );
  }

  private addData(send: Send): void {
    if (send.reference) {
      this.$data[send.reference] = send.value;
    }
  }

  private checkICDs(results: ICDResponse[], icds: string[]): boolean {
    this.$invalidICDs = [];

    results.forEach((result: ICDResponse, i: number) => {
      if (!result.icds.length) {
        this.$invalidICDs.push(icds[i]);
      }
    });

    return !this.invalidICDs.length;
  }
}
