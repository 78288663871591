import {Inject, Injectable} from '@angular/core';
import {combineLatest, EMPTY, from, Observable, of, throwError} from 'rxjs';
import {catchError, map, mergeMap, take} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {NGXLogger} from 'ngx-logger';
import 'firebase/compat/auth';
import {API_PERSON_TOKEN, API_USER_HMAC, ApiService} from './api.service';
import {formatDate} from '@angular/common';
import firebase from 'firebase/compat/app';
import {AuthResponse, Item} from '../models/auth-response';
import {COMPANY_TYPE} from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalStorageService, SignInMethods, StorageKeys } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private api: ApiService,
    private logger: NGXLogger,
    private router: Router,
    private localStorageService: LocalStorageService,
    private angularAuth: AngularFireAuth,
    @Inject('firebaseAuthSamlProject') private firebaseAuthSamlProject: AngularFireAuth,
  ) {}

  public get token$(): Observable<string | null> {
    const signInMethod = this.localStorageService.get(StorageKeys.SIGN_IN_METHOD);
    if (signInMethod === SignInMethods.SAML) {
      return this.firebaseAuthSamlProject.idToken;
    }
    return this.angularAuth.idToken;
  }

  public get user$(): Observable<firebase.User | null> {
    const signInMethod = this.localStorageService.get(StorageKeys.SIGN_IN_METHOD);
    if (signInMethod === SignInMethods.SAML) {
      return this.firebaseAuthSamlProject.user;
    }
    return this.angularAuth.user;
  }

  logout(): Promise<void> {
    const signInMethod = this.localStorageService.get(StorageKeys.SIGN_IN_METHOD);
    if (signInMethod === SignInMethods.SAML) {
      return this.firebaseAuthSamlProject.signOut();
    }
    return this.angularAuth.signOut();
  }

  /**
   * return encoded string to identify user
   */
  softLoginWithCpf(cpf: string, birthday: Date, captchaResponse: string, companyId?: string): Observable<AuthResponse | Item[]> {
    const payload = {
      cpf,
      birthday: formatDate(birthday, 'yyyy-MM-dd', 'pt-BR'),
      company_id: companyId,
      captcha_response: captchaResponse,
      company_type: COMPANY_TYPE
    };

    return this.api.http.post<AuthResponse>('/v4/b2c/people/auth', payload).pipe(
      mergeMap(res => from(
          this.angularAuth.signInWithCustomToken(res.jwt)
            .then(() => res)
            .catch(error => {
              this.logger.error(error);
            })
        )),
      catchError(err => {
        if (err?.status === 406) {
          this.router.navigate([`/${err.error?.company?.slug}`], { state: { redirect: history.state['redirect'] }});
          return EMPTY;
        }
        if (err?.status === 409) {
          return of(err.error?.companies);
        }
        return throwError(err);
      })
    );
  }

  getUserHmacHash(app: string): Observable<string> {
    return this.api.http.get<{hash: string}>(API_USER_HMAC(app)).pipe(
      map(result => result?.hash)
    );
  }
}
