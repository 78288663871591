import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, share, shareReplay, take } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { API_SETTINGS, ApiService } from './api.service';
import { CompanySettings } from '../models/company';
import { AuthService } from './auth.service';
import { Term } from '../models/term';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Messages } from '../models/message';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private settings$;
  private rulesAccepted = false;

  constructor(
    private logger: NGXLogger,
    private api: ApiService,
    private authService: AuthService
  ) {}

  public getTerms(): Observable<Term> {
    return this.api.http.get<Term>('/v4/b2c/terms');
  }

  public acceptTerms(term: Term): void {
    this.rulesAccepted = true;
    this.api.http.post(`/v4/b2c/terms/accept/${term.id}`, {}).subscribe();
  }

  public rulesAndTermsAccepted(): boolean {
    return this.rulesAccepted;
  }

  getSettings(): Observable<CompanySettings> {
    this.settings$ = this.settings$ || this.api.http.get<CompanySettings>(API_SETTINGS).pipe(
      shareReplay(1),
      catchError((error) => {
        this.logger.error(error);
        return EMPTY;
      }),
    );
    return this.settings$;
  }

  getCompanyBySlug(slug: string): Observable<Company> {
    return this.api.http.get<Company>(`/bff/v1/public/b2c/organizations/${slug}?id_type=slug`);
  }

  getCompanyByOrganizationId(organizationId: number): Observable<Company> {
    return this.api.http.get<Company>(`/bff/v1/public/b2c/organizations/${organizationId}`);
  }
}

export class Company {
  name: string;
  organization_id: number;
  company_id: string;
  logo_url: string;
  login_type: string;
}
