export enum ChatItemEnum {
  ITEM_TYPE_ANSWER_IMAGE = 'answer-image',
  ITEM_TYPE_ANSWER_TEXT = 'answer-text',
  ITEM_TYPE_QUESTION_HTML = 'html',
  ITEM_TYPE_QUESTION_IMAGE = 'image',
  ITEM_TYPE_QUESTION_TEXT = 'text',
}

export enum ComponentEnum {
  COMPONENT_TYPE_BUTTON_LINK = 'button-link',
  COMPONENT_TYPE_CHILDCARE_DEPENDENT = 'childcare-dependent',
  COMPONENT_TYPE_CHILDCARE_INSTITUTION = 'childcare-institution',
  COMPONENT_TYPE_DATE = 'date',
  COMPONENT_TYPE_FILE = 'file',
  COMPONENT_TYPE_MONTH_PICKER = 'month-picker',
  COMPONENT_TYPE_PUSH_NOTIFICATIONS = 'push-notifications',
  COMPONENT_TYPE_TEXT = 'text',
  COMPONENT_TYPE_TWO_BUTTONS = 'two-buttons',
}
