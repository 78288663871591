<ng-container *ngIf="company$ | async as company">
    <ng-container *ngIf="authUser$ | async as authUser; else loggedOutUser">
        <ng-container *ngIf="company.organization_id === userOrganizationId; else differentCompany">
            <app-logged-in-user
                [username]="userName || 'boas-vindas!'"
                [company]="company"
                (onLogout)="logout($event)"
                (onNext)="next($event)"
            ></app-logged-in-user>
        </ng-container>
    </ng-container>
    
    <ng-template #loggedOutUser>
        <app-logged-out-user
            [company]="company"
            (onLogIn)="login($event, company.login_type, company.organization_id)"
        ></app-logged-out-user>
    </ng-template>
    
    <ng-template #differentCompany><!-- FIXME: how to make OR here? -->
        <app-logged-out-user
            [company]="company"
            (onLogIn)="login($event, company.login_type, company.organization_id)"
        ></app-logged-out-user>
    </ng-template>
</ng-container>
