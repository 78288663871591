import { Injectable } from '@angular/core';
import {
  API_SAVE_PUSH_NOTIFICATION_TOKEN,
  ApiService
} from './api.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  constructor(
    private api: ApiService,
    private authService: AuthService,
  ) { }

  savePushNotificationToken(enabled: boolean, token: string | null, organizationId: number, personId: string) {
    return this.api.http.put(API_SAVE_PUSH_NOTIFICATION_TOKEN(organizationId, personId), { enabled, token, });
  }
}
