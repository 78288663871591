import { Injectable } from '@angular/core';
import {
  API_CALLBACK_NOTIFICATION,
  ApiService
} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    private api: ApiService,
  ) { }

  callbackNotification(payload: any) {
    return this.api.http.post(API_CALLBACK_NOTIFICATION, { payload });
  }
}
