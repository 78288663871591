<div class="first-container">
    <div class="user-greetings font-weight-700 text-align-center">
        <div class="font-size-24">Olá, {{ username }}</div>
        <button mat-button (click)="logout()" class="font-size-16">Sair</button>
    </div>
    <img src="assets/enviei_colorido.svg" alt="enviei.me" class="enviei-logo mb-16"><br />
    <img *ngIf="showImage" [src]="company.logo_url" alt="Logotipo da empresa {{ company.name }}" class="company-logo mb-32" (error)="onLogoImageLoadError()" />
    <span *ngIf="!showImage" class="font-size-32 font-weight-700 text-transform-uppercase">{{ company.name }}</span>
</div>

<div class="second-container">
    <div class="font-size-32 font-weight-500 text-align-center line-height-32 mb-32">Cadastre seus documentos</div>
    <button mat-button class="login-button font-size-16 font-weight-500 border-radius-32 py-8 white-font full-width mb-32" (click)="next()">Próximo</button>
    <div>
        <span class="mr-4 vertical-align-middle">feito por</span>
        <img src="assets/closecare_colorido.svg" alt="Closecare"
            class="closecare-logo font-size-14 font-weight-300 vertical-align-middle">
    </div>
</div>
