import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ChatItemEnum } from '../../dynamic-form.enums';
import { ChatItemType } from '../../entities/dynamic-form.entity';

@Component({
  selector: 'app-chat-history',
  templateUrl: './chat-history.component.html',
  styleUrls: ['./chat-history.component.scss'],
})
export class ChatHistoryComponent implements OnChanges {
  @Input()
  chat: ChatItemType[];

  @Input()
  typing: boolean;

  @Input()
  step: number;

  @Output()
  undoEvent: EventEmitter<boolean>;

  @Output()
  openFilePickerEvent: EventEmitter<boolean>;

  constructor() {
    this.undoEvent = new EventEmitter<boolean>();
    this.openFilePickerEvent = new EventEmitter<boolean>();
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.autoScroll();
    }, 100);
  }

  autoScroll(): void {
    const componentArea = document.getElementsByClassName('chat-history');
    if (componentArea.length > 0) {
      const scrollHeight = componentArea[0].scrollHeight;
      componentArea[0].scroll(0, scrollHeight);
    }
  }

  undo() {
    this.undoEvent.emit(true);
  }

  openFilePicker() {
    this.openFilePickerEvent.emit(true);
  }

  getClasses(item: ChatItemType) {
    let classes: string;
    if (item.files.length === 1) {
      classes = 'one-image';
    } else if (item.files.length === 2) {
      classes = 'two-images';
    } else if (item.files.length === 3) {
      classes = 'three-images';
    } else if (item.files.length === 4) {
      classes = 'four-images';
    } else {
      classes = 'more-than-four-images';
    }
    return item.imageType ? classes : `${classes} pb-4`;
  }

  get ChatItemEnum() {
    return ChatItemEnum;
  }
}
