import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SupportChatService, THEME } from '../../services/support-chat.service';

@UntilDestroy()
@Component({
  selector: 'app-registration-error',
  templateUrl: './registration-error.component.html',
  styleUrls: ['./registration-error.component.scss']
})
export class RegistrationErrorComponent implements OnInit, OnDestroy {
  title: string;
  body: string;

  constructor(private supportChatService: SupportChatService) {
    this.title = 'Não foi possível finalizar o cadastro de auxílio-creche';
    this.body = 'Entre em contato com o RH da sua empresa para poder completar o processo';
  }

  ngOnInit(): void {
    this.supportChatService.setTheme(THEME.dark);
  }

  ngOnDestroy(): void {
    this.supportChatService.setTheme(THEME.light);
  }
}
