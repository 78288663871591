import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FileDialog } from '../../models/file.model';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview-dialog.component.html',
  styleUrls: ['./file-preview-dialog.component.css']
})

export class FilePreviewDialogComponent {
  fileDialog: FileDialog;
  fileSrc: string | Uint8Array;
  framed: boolean;

  @ViewChild('imageElement') imageElement: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fileService: FileService
  ) {
    this.fileDialog = this.fileService.fileDialog;
    activatedRoute.queryParams.subscribe((params) => {
      this.fileService.namespace = params.namespace;
    });
  }

  static openModal(
    router: Router,
    route: ActivatedRoute,
    namespace: string
  ): Promise<boolean> {
    return router.navigate(
      ['', { outlets: { modal: 'visualizacao' } }],
      { relativeTo: route, queryParams: { namespace, } }
    );
  }

  redo(): void {
    this.fileService.showFileType = true;
    history.back();
  }

  confirm(): void {
    this.fileService.addFile(this.fileDialog);
    history.back();
  }
}
