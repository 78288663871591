<div *ngIf="currentComponent" [ngClass]="currentComponent.direction === 'vertical' ? 'flex-column' : 'flex-row'">
  <button
    *ngFor="let option of currentComponent.options"
    class="font-size-16 border-radius-32 py-16 white-font"
    (click)="done(option)"
  >
    <mat-icon *ngIf="option.icon" class="mr-8">{{ option.icon }}</mat-icon>
    {{ option.label }}
  </button>
</div>
