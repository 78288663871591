import {
  Component, Input
} from '@angular/core';
import { Router } from '@angular/router';
import { ComponentType } from '../../entities/dynamic-form.entity';

@Component({
  selector: 'app-button-link',
  templateUrl: './button-link.component.html',
  styleUrls: ['./button-link.component.scss'],
})
export class ButtonLinkComponent {
  @Input()
  currentComponent: ComponentType;

  constructor(private router: Router) {}

  goto() {
    this.router.navigate([this.currentComponent.link]);
  }
}
