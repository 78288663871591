import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { DocumentTypesService } from '../../services/document-types.service';
import { DocumentCategories, DocumentCategory, DocumentTypeList } from './entities/document-types.entity';
import { DocTypeService } from 'src/app/services/doc-type.service';
import { SickNoteType } from 'src/app/models/sick-note';

@Injectable({ providedIn: 'root' })
export class DocumentTypesResolver implements Resolve<DocumentTypeList> {
  constructor(
    public router: Router,
    private documentTypesService: DocumentTypesService,
    public docTypeService: DocTypeService,
  ) {}

  resolve(): Observable<DocumentTypeList> {
    return forkJoin({
      sick_note_types: this.docTypeService.getDocTypes(),
      document_types: this.documentTypesService.getDocumentsTypes(),
    })
    .pipe(map(({ sick_note_types, document_types }) => {
      if (sick_note_types.length === 0) {
        return document_types;
      } else {
        return {
          categories: [{
            id: 1,
            label: DocumentCategories.SICK_NOTE,
            types: sick_note_types.map((sick_note_type) => ({
              label: sick_note_type.name,
            }))
        } as DocumentCategory].concat(document_types.categories)
        };
      }
    }));
  }
}
