import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject, of } from 'rxjs';

import { FileData, FileDialog } from '../models/file.model';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  showFileType: boolean;
  answer = new Subject<Record<string, FileData[]>>();
  fileDialog: FileDialog;

  private _namespace = 'default';
  private _data = new BehaviorSubject<Record<string, FileData[]>>({ [this._namespace]: [] });

  constructor(private http: HttpClient) { }

  get data$(): Observable<FileData[]> {
    return of(this.data);
  }

  get length(): number {
    return this.data.length;
  }

  set namespace(namespace: string) {
    this._namespace = namespace;
  }

  private get data(): FileData[] {
    const data = this._data.getValue();
    if (!Object.keys(data).includes(this._namespace)) {
      data[this._namespace] = [];
    }
    return data[this._namespace];
  }

  getBlob(fileUrl: string): Observable<Blob> {
    return this.http.get(fileUrl, { responseType: 'blob' });
  }

  addFile({index, fileData}: FileDialog): void {
    this.data[index] = fileData;
    this._data.next({ [this._namespace]: this.data });
  }

  removeFile(index: number): void {
    this.data.splice(index, 1);
    this._data.next({ [this._namespace]: this.data });
  }

  resetFiles(): void {
    this._data.next({ default: [] });
  }

  setAnswer() {
    this.answer.next({ [this._namespace]: this.data });
  }
}
