<mat-toolbar>
  <button mat-icon-button (click)="back()" color="primary" aria-label="Voltar">
    <mat-icon fontSet="material-icons-outlined">arrow_back_ios</mat-icon>
  </button>

  <div *ngIf="!!logoUrl">
    <img [src]="logoUrl" alt="logo" height="30px">
  </div>

  <!--
  <button mat-icon-button color="primary" (click)="menuService.open.next()">
    <mat-icon fontSet="material-icons-outlined">menu</mat-icon>
  </button>
  -->
</mat-toolbar>
<div class="overflow-container">
  <div *ngIf="titleService.title$ | async as title" class="mat-headline">
    {{title}}
  </div>
  <router-outlet></router-outlet>
</div>
