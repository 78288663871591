import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Term, TermContent } from '../../models/term';
import { CompanyService } from '../../services/company.service';

export type TermType = 'complete' | 'simple';

@UntilDestroy()
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
  term: Term;
  termControls: FormArray;
  type: TermType;
  buttonLabel = 'Próximo';

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) {}

  get content(): string {
    return this.type === 'complete' ? this.term?.content?.complete : this.term?.content?.simple;
  }

  get contentBody(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.content);
  }

  ngOnInit(): void {
    this.termControls = this.fb.array([]);

    this.activatedRoute.data
      .pipe(untilDestroyed(this))
      .subscribe((data: {term: Term; type: TermType}) => {
        this.term = data.term;
        this.type = data.type;
        if (this.type === 'complete') {
          this.buttonLabel = 'Fechar';
        } else {
          this.term.conditions.forEach(() => {
            this.termControls.push(this.fb.control(false, [Validators.requiredTrue]));
          });
        }
      });
  }

  saveOrClose(): void {
    if (this.type === 'complete') {
      this.router.navigate(['/s/termos']); // go back to simple terms
    } else {
      this.companyService.acceptTerms(this.term);
      this.router.navigate(['/c']);
    }
  }

  showCompleteRules(): void {
    this.router.navigate(['/s/termos/completo']);
  }
}
