import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ChildcareDependentDialogModule } from '../childcare-dependent-dialog/childcare-dependent-dialog.module';
import { ChildcareDependentComponent } from './childcare-dependent.component';

@NgModule({
  declarations: [ChildcareDependentComponent],
  exports: [ChildcareDependentComponent],
  imports: [
    ChildcareDependentDialogModule,
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class ChildcareDependentModule {}
