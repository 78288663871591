import {
  Component, Inject
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChildcareEnrollmentsService } from 'src/app/services/childcare-enrollments.service';
import { ChildcareInstitution } from '../childcare.entities';

@Component({
  selector: 'app-childcare-institution-dialog',
  templateUrl: './childcare-institution-dialog.component.html',
  styleUrls: ['./childcare-institution-dialog.component.scss']
})
export class ChildcareInstitutionDialogComponent {
  formControl: FormControl;
  institutions: ChildcareInstitution[];
  loading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChildcareInstitutionDialogData,
    public dialogRef: MatDialogRef<ChildcareInstitutionDialogComponent>,
    private fb: FormBuilder,
    private childcareEnrollmentsService: ChildcareEnrollmentsService
  ) {
    this.formControl = fb.control(null);

    this.loading = true;
    this.childcareEnrollmentsService.getChildcareEnrollments(data.personDependentId)
      .subscribe((response) => {
        this.institutions = [
          ...response['items'],
          {
            specific: {
              id: '-1',
              name: 'Nova instituição'
            }
          }
        ];
        this.loading = false;
      });
  }

  select() {
    this.dialogRef.close(this.formControl.value);
  }
}

class ChildcareInstitutionDialogData {
  personDependentId: number;
  title: string;
  button: string;
}
