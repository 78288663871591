import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

import { SimpleDialogComponent } from '../../components/simple-dialog/simple-dialog.component';
import { FileData, FileDialog } from '../../models/file.model';
import { FilePreviewDialogComponent } from '../../pages/file-preview/file-preview-dialog.component';
import { FileService } from '../../services/file.service';
import { FileBottomSheetComponent } from './../../components/files-bottom-sheet/files-bottom-sheet.component';

@UntilDestroy()
@Component({
  selector: 'app-file-selected',
  templateUrl: './file-selected-dialog.component.html',
  styleUrls: ['./file-selected-dialog.component.scss']
})

export class FileSelectedDialogComponent implements OnInit {
  namespace: string;
  imageType: string;

  constructor(
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fileService: FileService
  ) {
    activatedRoute.queryParams.subscribe((params) => {
      this.fileService.namespace = params.namespace;
      this.namespace = params.namespace;
      if (params.imageType) {
        this.imageType = params.imageType;
      }
    });
  }

  static openModal(
    router: Router,
    route: ActivatedRoute,
    namespace: string,
    imageType?: string,
  ): Promise<boolean> {
    return router.navigate(
      ['', { outlets: { modal: 'arquivos' } }],
      { relativeTo: route, queryParams: { namespace, imageType } }
    );
  }

  ngOnInit(): void {
    if (this.fileService.showFileType) {
      this.showFileType();
    }
  }

  get files(): Observable<FileData[]> {
    return this.fileService.data$;
  }

  showFileType(index: number = this.fileService.length): void {
    this.fileService.showFileType = false;
    this.bottomSheet.open(FileBottomSheetComponent, {
      panelClass: 'answer-bottom-sheet'
    })
      .afterDismissed()
      .subscribe((data: FileDialog) => {
        if (data) {
          this.showFilePreview(data, index);
        }
      });
  }

  removeFile(index: number): void {
    SimpleDialogComponent
      .open(this.dialog, {
        content: 'Deseja remover a imagem?',
        noButton: 'Cancelar',
        okButton: 'Continuar',
        panelClass: 'small-dialog'
      })
      .beforeClosed()
      .pipe(untilDestroyed(this))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.fileService.removeFile(index);
        }
      });
  }

  back(): void {
    this.fileService.resetFiles();
    history.back();
  }

  continue(): void {
    this.fileService.setAnswer();
    history.back();
  }

  private showFilePreview(fileDialog: FileDialog, index: number): void {
    this.fileService.fileDialog = { ...fileDialog, index };

    FilePreviewDialogComponent
      .openModal(this.router, this.activatedRoute, this.namespace);
  }
}
