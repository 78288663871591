import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ChatService } from './chat.service';
import { Intercom } from 'ng-intercom';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, mergeMap } from 'rxjs/operators';
import { combineLatest, from, of } from 'rxjs';
import { environment } from '../../environments/environment';

export enum THEME {
  light = 'light',
  dark = 'dark'
}

export interface SupportChatData {
  user_id?: string;
  user_hash?: string;
  email?: string;
  phone?: string;
  name?: string;
  company?: {
    id: string;
    name: string;
  };
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SupportChatService {
  THEMES = THEME;
  private _theme: THEME = THEME.light;


  constructor(
    private authService: AuthService,
    private intercom: Intercom
  ) {}

  get theme(): THEME {
    return this._theme;
  }

  init(): void {
    this.authService.user$.pipe(
      untilDestroyed(this),
      mergeMap(user => {
        if (!user) {
          return of([]);
        }
        const claims$ = from(user?.getIdTokenResult().then((tr) => tr?.claims as any));
        const userHash$ = this.authService.getUserHmacHash('intercom');
        return combineLatest([of(user.uid), claims$, userHash$]);
      }),
      catchError(() => [])
    ).subscribe(([uid, claims, hash]) => {
      this.intercom.boot({
        app_id: environment.intercomAppId,
        user_id: uid || undefined,
        user_hash: hash,
        name: uid || undefined,
        app_name: 'Funcionario',
        app_version: environment.version,
        company: {
          id: claims?.company_id || undefined,
          name: claims?.company_id || undefined
        },
        hide_default_launcher: true,
        custom_launcher_selector: '#chatButton',
        widget: {
          activator: '#intercom'
        }
      });
    });
  }

  update(supportChatData: SupportChatData) {
    this.intercom.update(supportChatData);
  }

  setTheme(t: THEME): void {
    setTimeout(() => {
      this._theme = t;
    });
  }
}
