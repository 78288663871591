import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateDiffPipe } from './date-diff.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TypeListToStringPipe } from './type-list-to-string.pipe';



@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DateFormatPipe,
    DateDiffPipe,
    SafeHtmlPipe,
    TypeListToStringPipe
  ],
  exports: [
    DateFormatPipe,
    DateDiffPipe,
    SafeHtmlPipe,
    TypeListToStringPipe
  ]
})
export class PipesModule {}
