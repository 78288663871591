import { Injectable } from '@angular/core';
import { differenceInDays, isBefore, parse, parseISO } from 'date-fns';
import { map } from 'rxjs/operators';
import {
  API_CHILDCARE_ENROLLMENTS_LIST,
  ApiService
} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ChildcareEnrollmentsService {
  constructor(private api: ApiService) { }

  getChildcareEnrollments(personDependentId: number) {
    let url = API_CHILDCARE_ENROLLMENTS_LIST;
    url += `?person_dependent_id=${personDependentId}`;
    for (const status of ['valid', 'pending', 'verified', 'filled', 'triage', 'warning']) {
      url += `&status=${status}`;
    }
    return this.api.http.get(url.toString()).pipe(
      map((response) => ({
        ...response,
        items: response['items']
        .sort((a, b) => {
          // ************************************************************
          //  ¯\_ (ツ)_/¯
          if (!a.specific.contract_date && !b.specific.contract_date) {
            return 0;
          }
          if (!a.specific.contract_date) {
            return 999999;
          }
          if (!b.specific.contract_date) {
            return -999999;
          }
          // ************************************************************
          const date_a = parse(a.specific.contract_date, 'yyyy-MM-dd', Date.now());
          const date_b = parse(b.specific.contract_date, 'yyyy-MM-dd', Date.now());
          const d = differenceInDays(date_b, date_a);
          return d;
        })
        .map((item) => ({
          ...item,
          contract_date: parseISO(item.contract_date),
        }))
      }))
    );
  }
}
