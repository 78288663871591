<mat-form-field class="mat-form-field-custom-date" appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [formControl]="dateFormControl"
    [matDatepicker]="picker"
    class="hidden"
  />
  <input
    matInput
    [placeholder]="placeholder"
    [formControl]="monthFormControl"
    (click)="openDatePicker(picker)"
    (focus)="openDatePicker(picker)"
  />
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker panelClass="month-selector" startView="multi-year" [startAt]="startDate" (monthSelected)="closeDatePicker($event, picker)"></mat-datepicker>
</mat-form-field>