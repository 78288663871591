<span mat-dialog-title class="font-size-22 font-weight-400">{{ data.title }}</span>

<mat-dialog-content class="pt-24 borders" [ngClass]="loading && 'on-loading'">
  <app-spinner *ngIf="loading"></app-spinner>
  <mat-radio-group class="flex-column" [formControl]="formControl" *ngIf="!loading">
    <mat-radio-button *ngFor="let institution of institutions" class="mb-24" [value]="institution.specific">
      {{ institution.specific.name }}
      <ng-container *ngIf="institution.specific.contract_date">
        <br />{{ institution.specific.contract_date | date_format }}
      </ng-container>
    </mat-radio-button>
  </mat-radio-group>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="primary" (click)="select()" *ngIf="!loading">{{ data.button }}</button>
</mat-dialog-actions>