<ng-container *ngIf="authUser">
  <ng-container *ngIf="company">
    <app-logged-in-user
      class="home-container-with-user"
      [username]="userName || 'boas-vindas!'"
      [company]="company"
      (onLogout)="logout($event)"
      (onNext)="next($event)"
    ></app-logged-in-user>
  </ng-container>
</ng-container>

<ng-container *ngIf="!authUser && !company">
  <div class="home-container-without-user p-32">
    <div class="main-logo">
      <img src="assets/enviei_me.svg" alt="enviei.me" class="without-user">
    </div>

    <div class="container">
      <div class="mat-headline font-size-32 font-weight-500 white-font">Cadastre seus documentos</div>
      <button mat-raised-button data-test="next" class="closecare-button primary-bg" routerLink="/s" aria-label="Próximo">
        Próximo
      </button>
    </div>
    <div class="poweredby font-size-14 font-weight-300 white-font">
      <span>feito por </span><img src="assets/closecare.svg" alt="closecare">
    </div>
  </div>
</ng-container>
