export const TIMER = {
  min: 300,
  max: 1200
};

export const STARTERS = {
  phone: 'startWithPhone',
  email: 'startWithEmail',
  phoneAndEmail: 'startWithPhoneAndEmail'
};

export const FLOW = {
  startWithPhone: {
    text: '<p>Olá, iremos enviar um link para você acompanhar o status do atestado nos contatos a seguir:<br><strong>celular: $phone</strong></p>',
    breakStrong: true,
    branch: true,
    onCancel: ['phone', 'email'],
    load: {
      default: 'vaccineTypes'
    },
    nexts: {
      edit: 'updatePersonalData',
      noAllowancedVaccine: 'vaccineType',
      dailyAllowancedVaccine: 'vaccineType',
      timeAllowancedVaccine: 'vaccineType',
      basic: 'date',
      complete: 'date'
    }
  },
  startWithEmail: {
    text: '<p>Olá, iremos enviar um link para você acompanhar o status do atestado nos contatos a seguir:<br><strong>email: $email</strong></p>',
    breakStrong: true,
    branch: true,
    onCancel: ['phone', 'email'],
    nexts: {
      edit: 'updatePersonalData',
      noAllowancedVaccine: 'vaccineType',
      dailyAllowancedVaccine: 'vaccineType',
      timeAllowancedVaccine: 'vaccineType',
      basic: 'date',
      complete: 'date',
    },
    load: {
      default: 'vaccineTypes'
    }
  },
  startWithPhoneAndEmail: {
    text: '<p>Olá, iremos enviar um link para você acompanhar o status do atestado nos contatos a seguir:<br><strong>celular: $phone<br>email: $email</strong></p>',
    breakStrong: true,
    branch: true,
    onCancel: ['phone', 'email'],
    nexts: {
      edit: 'updatePersonalData',
      noAllowancedVaccine: 'vaccineType',
      dailyAllowancedVaccine: 'vaccineType',
      timeAllowancedVaccine: 'vaccineType',
      basic: 'date',
      complete: 'date',
    },
    load: {
      default: 'vaccineTypes'
    }
  },
  updatePersonalData: {
    answer: {
      type: 'correction',
      bottomSheet: {
        description: 'Corrija as informações abaixo',
        fields: [
          {
            type: 'phone',
            placeholder: 'Celular',
            reference: {
              key: 'phone'
            }
          }, {
            type: 'email',
            placeholder: 'E-mail',
            reference: {
              key: 'email'
            }
          }
        ],
        button: {
          label: 'Alterar'
        }
      }
    },
    load: {
      default: 'professionals'
    }
  },
  vaccineType: {
    text: '<p>Selecione qual a vacina que você tomou</p>',
    answer: {
      type: 'picker',
      button: {
        label: 'Selecione a vacina'
      },
      picker: 'vaccine_types',
      reference: {
        key: 'vaccine_name'
      }
    },
    onCancel: ['vaccine_name'],
    nexts: {
      default: 'dose'
    }
  },
  dose: {
    text: '<p>Qual dose da vacina você tomou?</p>',
    answer: {
      type: 'picker',
      button: {
        label: 'Selecione a dose'
      },
      picker: 'doses',
      reference: {
        key: 'vaccine_dose'
      }
    },
    onCancel: ['vaccine_dose'],
    nexts: {
      default: 'covidDate'
    }
  },
  covidDate: {
    text: '<p>Quando tomou a vacina?</p>',
    answer: {
      type: 'date',
      button: {
        label: 'Selecione a data'
      },
      minTime: {
        amount: -10,
        period: 'years'
      },
      maxTime: {
        amount: +7,
        period: 'days'
      },
      reference: {
        key: 'appointment_date'
      }
    },
    branch: true,
    onCancel: [
      'appointment_date',
      'expiration_date'
    ],
    nexts: {
      noAllowancedVaccine: 'sickNoteUpload',
      dailyAllowancedVaccine: 'dailyAllowance',
      timeAllowancedVaccine: 'timeAllowance',
      basic: 'send',
      complete: 'clearance',
    }
  },
  sickNoteUpload: {
    text: '<p>Tire foto ou anexe o arquivo do atestado cadastrado</p>',
    answer: {
      type: 'buttons',
      buttons: [
        {
          label: 'Continuar',
          correction: 'fileType'
        }
      ]
    },
    onCancel: ['file'],
    nexts: {
      default: 'send',
    }
  },
  companyRules: {
    text: '<p>Atenção: Somente o comprovante de vacina não dá direito ao abono de ausência ao trabalho. <strong>Para ter abono, você precisa cadastrar um atestado ou declaração desse período.</strong></p>',
    nexts: {
      default: 'send'
    }
  },
  dailyAllowance: {
    text: '<p>Com o comprovante de vacina, sua empresa <strong>abona $allowance_days</strong> de ausência ao trabalho. <strong>Afastamento $allowance_period</strong></p>',
    nexts: {
      default: 'sickNoteUpload',
    }
  },
  timeAllowance: {
    text: '<p>Sua empresa <strong>abona até $allowance_hours de ausência</strong> no dia da vacinação.<br><strong>A partir de que horas você se ausentou do trabalho?</strong></p>',
    answer: {
      type: 'time',
      button: {
        label: 'Selecione o horário'
      },
      title: 'Horário de início',
      reference: {
        key: 'appointment_date',
        type: 'hours',
        dependence: 'appointment_date'
      }
    },
    nexts: {
      default: 'sickNoteUpload',
    }
  },
  date: {
    text: '<p>Qual é a data de início do afastamento?</p>',
    answer: {
      type: 'date',
      button: {
        label: 'Selecione a data de início'
      },
      minTime: {
        amount: -10,
        period: 'years'
      },
      maxTime: {
        amount: +180,
        period: 'days'
      },
      reference: {
        key: 'appointment_date'
      }
    },
    branch: true,
    onCancel: ['appointment_date'],
    nexts: {
      basic: 'sickNoteUpload',
      complete: 'clearance',
    }
  },
  clearance: {
    text: '<p>O tempo de afastamento está em dias ou horas?</p>',
    answer: {
      type: 'buttons',
      buttons: [
        {
          value: 'days',
          label: 'Dias'
        }, {
          value: 'hours',
          label: 'Horas'
        }
      ]
    },
    nexts: {
      days: 'completeDays',
      hours: 'timeStart'
    }
  },
  completeDays: {
    text: '<p>Quantos dias são de afastamento?</p>',
    answer: {
      type: 'number',
      placeholder: 'Digite a quantidade de dias',
      reference: {
        key: 'expiration_date',
        dependence: 'appointment_date'
      }
    },
    onCancel: ['expiration_date'],
    nexts: {
      default: 'completePeriod'
    }
  },
  timeStart: {
    text: '<p>Qual o horário de início do seu atestado?</p>',
    answer: {
      type: 'time',
      button: {
        label: 'Selecione o horário'
      },
      title: 'Horário de início',
      reference: {
        key: 'appointment_date',
        type: 'hours',
        dependence: 'appointment_date'
      }
    },
    nexts: {
      default: 'timeEnd'
    }
  },
  timeEnd: {
    text: '<p>Digite agora o horário final do seu atestado</p>',
    answer: {
      type: 'time',
      button: {
        label: 'Selecione o horário'
      },
      title: 'Horário final',
      maxTime: {
        amount: +23,
        period: 'hours'
      },
      reference: {
        key: 'expiration_date',
        type: 'hours',
        dependence: 'appointment_date'
      }
    },
    onCancel: ['expiration_date'],
    nexts: {
      default: 'completePeriod'
    }
  },
  completePeriod: {
    text: '<p>Afastamento $period_date</p>',
    nexts: {
      default: 'premises'
    }
  },
  premises: {
    text: '<p>Em qual estabelecimento de saúde você pegou esse documento?</p>',
    answer: {
      type: 'text',
      placeholder: 'Digite o local',
      reference: {
        key: 'premises'
      }
    },
    onCancel: ['premises'],
    nexts: {
      default: 'sickNoteCID1'
    }
  },
  sickNoteCID1: {
    text: '<p>Digite o CID. O CID é o código da doença que causou o afastamento</p>',
    nexts: {
      default: 'sickNoteCID2'
    },
  },
  sickNoteCID2: {
    text: '<p>Para te ajudar este é um EXEMPLO de como ele aparece no atestado</p>',
    nexts: {
      default: 'sickNoteCID3'
    },
  },
  sickNoteCID3: {
    text: '<img src="assets/example-icd.jpg">',
    image: true,
    answer: {
      type: 'icd',
      placeholder: 'Digite o CID',
      cancel: 'Não tem CID',
      reference: {
        key: 'icds'
      }
    },
    onCancel: ['icds'],
    load: {
      B12: 'icds',
      B123: 'icds',
      invalid: 'icds',
      valid: 'professionalTypes',
      cancel: 'professionalTypes'
    },
    nexts: {
      B12: 'icdImage',
      B123: 'icdImage',
      invalid: 'icdWrong',
      valid: 'sickNoteProfessional',
      cancel: 'sickNoteProfessional',
    }
  },
  icdImage: {
    text: '<p>O CID da imagem é apenas um exemplo. Digite o CID que aparece no seu atestado.</p>',
    answer: {
      type: 'icd',
      placeholder: 'Digite o CID',
      cancel: 'Não tem CID',
      reference: {
        key: 'icds'
      }
    },
    onCancel: ['icds'],
    load: {
      B12: 'icds',
      B123: 'icds',
      invalid: 'icds',
      valid: 'professionalTypes',
      cancel: 'professionalTypes'
    },
    nexts: {
      B12: 'icdImage',
      B123: 'icdImage',
      invalid: 'icdWrong',
      valid: 'sickNoteProfessional',
      cancel: 'sickNoteProfessional',
    }
  },
  icdWrong: {
    text: '<p>Atenção, $invalid_icd!<br>Verifique se digitou o CID corretamente ou entre em contato com o local em que foi atendido.</p>',
    answer: {
      type: 'icd',
      placeholder: 'Digite o CID',
      cancel: 'Não tem CID',
      reference: {
        key: 'icds'
      }
    },
    warning: true,
    onCancel: ['icds'],
    load: {
      B12: 'icds',
      B123: 'icds',
      invalid: 'icds',
      valid: 'professionalTypes',
      cancel: 'professionalTypes'
    },
    nexts: {
      B12: 'icdImage',
      B123: 'icdImage',
      invalid: 'icdWrong',
      valid: 'sickNoteProfessional',
      cancel: 'sickNoteProfessional',
    }
  },
  sickNoteProfessional: {
    text: '<p>Agora selecione qual foi o profissional que te atendeu</p>',
    answer: {
      type: 'picker',
      button: {
        label: 'Selecione o profissional'
      },
      picker: 'professional_title',
      reference: {
        key: 'professional_type'
      }
    },
    onCancel: ['professional_type'],
    priority: true,
    nexts: {
      OUTRO: 'professionalOtherState',
      NENHUM: 'sickNoteUpload',
      default: 'professionalTypeState'
    }
  },
  professionalTypeState: {
    text: '<p>Selecione o estado de origem do $professional_type</p>',
    answer: {
      type: 'picker',
      button: {
        label: 'Selecione o estado'
      },
      picker: 'states',
      cancel: 'Não tem estado',
      reference: {
        key: 'professional_state'
      }
    },
    onCancel: [
      'professional_state',
      'professional_name'
    ],
    nexts: {
      cancel: 'professionalTypeOptions',
      default: 'professionalTypeOptions'
    }
  },
  professionalTypeOptions: {
    text: '<p>Digite o número de $professional_type do $professional_title</p>',
    answer: {
      type: 'code',
      placeholder: 'Digite o $professional_type',
      cancel: 'Não tem $professional_type',
      reference: {
        key: 'professional_id'
      },
      prefix: {
        conditions: [
          {
            key: 'professional_type',
            value: 'CRM'
          }, {
            key: 'professional_state',
            value: 'RJ'
          }
        ],
        fill: '52'
      }
    },
    load: {
      default: 'professionals'
    },
    onCancel: [
      'professional_id',
      'professional_name'
    ],
    nexts: {
      default: 'professionalName',
      cancel: 'professionalName',
      CRM: 'professionalCRM',
      error: 'professionalError'
    }
  },
  professionalCRM: {
    text: '<p>O nome do $professional_title é $professional_name?</p>',
    answer: {
      type: 'buttons',
      buttons: [
        {
          value: 'cancel',
          label: 'Não',
          forceCancel: true,
          negative: true
        }, {
          value: 'yes',
          label: 'Sim'
        }
      ]
    },
    nexts: {
      cancel: 'professionalError',
      yes: 'sickNoteUpload'
    }
  },
  professionalError: {
    text: '<p>Atenção! Não foi possível encontrar o $professional_title. O número do $professional_type $professional_id e o estado $professional_state estão corretos?<p>',
    warning: true,
    answer: {
      type: 'buttons',
      buttons: [
        {
          value: 'yes',
          label: 'Está correto',
          negative: true,
        }, {
          label: 'Corrigir',
          correction: 'updateProfessionalData'
        }
      ]
    },
    nexts: {
      yes: 'professionalName',
      updateProfessionalData: 'updateProfessionalData',
      error: 'professionalError',
      CRM: 'professionalCRM'
    }
  },
  updateProfessionalData: {
    answer: {
      type: 'correction',
      picker: 'states',
      bottomSheet: {
        description: 'Corrija as informações abaixo',
        fields: [
          {
            type: 'number',
            placeholder: 'Digite o $professional_type',
            required: true,
            reference: {
              key: 'professional_id'
            }
          }, {
            type: 'picker',
            placeholder: 'Selecione o estado',
            required: true,
            picker: 'states',
            reference: {
              key: 'professional_state'
            }
          }
        ],
        button: {
          label: 'Corrigir'
        }
      }
    },
    load: {
      default: 'professionals'
    }
  },
  professionalName: {
    text: '<p>Diga qual é o nome do $professional_title',
    answer: {
      type: 'text',
      placeholder: 'Digite o nome',
      reference: {
        key: 'professional_name'
      }
    },
    onCancel: ['professional_name'],
    nexts: {
      default: 'sickNoteUpload'
    }
  },
  professionalOtherState: {
    text: '<p>Qual é o estado de origem do registro profissional</p>',
    answer: {
      type: 'picker',
      button: {
        label: 'Selecione o estado'
      },
      picker: 'states',
      cancel: 'Não tem estado',
      reference: {
        key: 'professional_state'
      }
    },
    onCancel: ['professional_state'],
    nexts: {
      cancel: 'professionalOtherName',
      default: 'professionalOther'
    }
  },
  professionalOther: {
    text: '<p>Digite o número de registro do profissional</p>',
    answer: {
      type: 'text',
      placeholder: 'Digite o registro profissional',
      cancel: 'Não tem registro profissional',
      reference: {
        key: 'professional_id'
      }
    },
    onCancel: ['professional_id'],
    nexts: {
      cancel: 'professionalOtherName',
      default: 'professionalOtherName'
    }
  },
  professionalOtherName: {
    text: '<p>Qual é o nome do profissional</p>',
    answer: {
      type: 'text',
      placeholder: 'Digite o nome',
      cancel: 'Não tem nome',
      reference: {
        key: 'professional_name'
      }
    },
    nexts: {
      cancel: 'sickNoteUpload',
      default: 'sickNoteUpload'
    }
  },
  send: {
    text: '<p>Pronto, agora é só clicar no botão enviar abaixo</p>',
    answer: {
      type: 'send',
      buttons: [{
        label: 'Enviar'
      }]
    }
  }
};
