import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthorizedGuard } from './guards/authorized.guard';
import { CodeGuard } from './guards/code.guard';
import { FileExistsGuard } from './guards/file-exists.guard';
import { TermsAcceptedGuard } from './guards/terms_accepted.guard';
import { BaseComponent } from './pages/base/base.component';
import { DocTypeComponent } from './pages/doc-type/doc-type.component';
import { DocumentCategoriesComponent } from './pages/document-categories/document-categories.component';
import { DocumentTypesResolver } from './pages/document-categories/document-types.resolver.';
import { DocumentTypesComponent } from './pages/document-types/document-types.component';
import { FilePreviewDialogComponent } from './pages/file-preview/file-preview-dialog.component';
import { HomeComponent } from './pages/home/home.component';
import { InformationsComponent } from './pages/informations/informations.component';
import { LoginComponent } from './pages/login/login.component';
import { RegistrationErrorComponent } from './pages/registration-error/registration-error.component';
import { SickNoteComponent } from './pages/sick-note/sick-note.component';
import { SsoResolver } from './pages/sso/sso-resolver.service';
import { SsoComponent } from './pages/sso/sso.component';
import { TermsComponent } from './pages/terms/terms.component';
import { ValidationComponent } from './pages/validation/validation.component';
import { DocumentResolverService } from './resolvers/document-resolver.service';
import { ProfessionalService } from './resolvers/professional-resolver.service';
import { TermsResolverService } from './resolvers/terms-resolver.service';
import { HomeResolver } from './pages/home/home-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    resolve: { userCompany: HomeResolver },
  },
  {
    path: 'f',
    loadChildren: () =>
      import('./pages/dynamic-form/dynamic-form.module').then(
        (m) => m.DynamicFormModule
      ),
  },
  {
    path: 'c',
    component: BaseComponent,
    canActivate: [AuthorizedGuard, CodeGuard, TermsAcceptedGuard],
    children: [
      {
        path: '',
        component: DocumentCategoriesComponent,
        resolve: { documentTypes: DocumentTypesResolver },
      }
    ]
  },
  {
    path: 't',
    component: BaseComponent,
    canActivate: [AuthorizedGuard, CodeGuard, TermsAcceptedGuard],
    children: [
      {
        path: '',
        component: DocumentTypesComponent,
      }
    ]
  },
  {
    path: 's',
    component: BaseComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        data: { title: 'Confirme seus dados' },
      },
      {
        path: 'validacao',
        component: ValidationComponent,
        data: { title: 'Acompanhe o status do documento' },
        canActivate: [AuthorizedGuard],
      },
      {
        path: 'termos',
        component: TermsComponent,
        data: { title: 'Resumo de termos e regras' },
        canActivate: [AuthorizedGuard, CodeGuard],
        resolve: { term: TermsResolverService },
      },
      {
        path: 'termos/completo',
        component: TermsComponent,
        data: { title: 'Termos e Regras completas', type: 'complete' },
        canActivate: [AuthorizedGuard, CodeGuard],
        resolve: { term: TermsResolverService },
      },
      {
        path: 'documento',
        component: DocTypeComponent,
        data: { title: 'Qual documento você vai cadastrar?' },
        canActivate: [AuthorizedGuard, CodeGuard, TermsAcceptedGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import('./pages/chat/chat.module').then((m) => m.ChatModule),
      },
      {
        path: 'data',
        component: SickNoteComponent,
        data: { title: 'Digite a data do atendimento' },
        canActivate: [
          AuthorizedGuard,
          CodeGuard,
          TermsAcceptedGuard,
          FileExistsGuard,
        ],
      },
    ],
  },
  {
    path: 'a',
    component: BaseComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/status/status.module').then((m) => m.StatusModule),
        resolve: { professionals: ProfessionalService },
        canActivate: [AuthorizedGuard],
      },
    ],
  },
  {
    path: 'e',
    component: RegistrationErrorComponent,
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'info/:shortId',
    component: InformationsComponent,
    canActivate: [AuthorizedGuard],
    resolve: { resolved: DocumentResolverService }
  },
  {
    path: 'visualizacao',
    component: FilePreviewDialogComponent,
    outlet: 'modal',
  },
  {
    path: ':slug',
    component: BaseComponent,
    children: [
      {
        path: '',
        component: SsoComponent,
        resolve: { resolved: SsoResolver },
      }
    ]
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
