import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { SpinnerModule } from 'src/app/components/spinner/spinner.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ChildcareInstitutionDialogComponent } from './childcare-institution-dialog.component';

@NgModule({
  declarations: [ChildcareInstitutionDialogComponent],
  exports: [ChildcareInstitutionDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatRadioModule,
    PipesModule,
    ReactiveFormsModule,
    SpinnerModule,
  ],
})
export class ChildcareInstitutionDialogModule { }
