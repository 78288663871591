import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Company } from 'src/app/services/company.service';

@Component({
  selector: 'app-logged-out-user',
  templateUrl: './logged-out-user.component.html',
  styleUrls: ['./logged-out-user.component.scss']
})
export class LoggedOutUserComponent {
  showImage: boolean;

  @Input()
  company: Company;

  @Output()
  onLogIn: EventEmitter<string> = new EventEmitter();

  constructor() {
    this.showImage = true;
  }

  onLogoImageLoadError() {
    this.showImage = false;
  }

  next() {
    this.onLogIn.emit('login');
  }
}
