export enum DocumentCategories {
    SICK_NOTE = 'Afastamento',
    OCCUPATIONAL = 'Ocupacional',
    REFUND = 'Reembolso',
}

export class DocumentType {
    id: number;
    label: string;
    type_id: number;
}

export class DocumentCategory {
    id: number;
    label: string;
    types: DocumentType[];
}

export class DocumentTypeList {
    categories: DocumentCategory[];
}
