import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  constructor(private api: ApiService) {}

  getProvider(organizationId: number): Observable<Provider> {
    return this.api.http.get<Provider>(`/bff/v1/public/b2c/organizations/${organizationId}/auth-configs`);
  }
}

export class Provider {
    auth_tenant_id: string;
    provider_id: string;
}
