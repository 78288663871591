import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonLinkComponent } from './button-link.component';

@NgModule({
  declarations: [ButtonLinkComponent],
  exports: [ButtonLinkComponent],
  imports: [
    CommonModule,
  ],
})
export class ButtonLinkModule {}
