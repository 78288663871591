import { DocumentReason } from '../pages/dynamic-form/entities/document.entity';
import { REASONS } from '../pages/status/status.settings';

export enum DocumentStatus {
  VALID = 'valid',
  INVALID = 'invalid',
  TRIAGE = 'triage',
  PENDING = 'pending',
  FILLED = 'filled',
  VERIFIED = 'verified',
  WARNING = 'warning',
}

/* SickNoteExtras ********************************************************************************/
export class Location {
  code: string;
  id: number;
  name: string;
}

export class Metadata {
  created_at: string;
  registered_by_uid: string;
  source: string;
  updated_at: string;
  updated_by_uid: string;
}

export class Person {
  cpf: string;
  document_id: string;
  document_type: string;
  id: string;
  name: string;
  social_name: string;
}

export class StatusReason {
  description: string;
  id: string;
  public: boolean;
  status: string;
  threshold: string;
  value: string;
}

export class Type {
  code: string;
  id: number;
  name: string;
}

export class DocumentType {
  id: number;
  code: string;
  label: string;
  family: string;
  social_security: boolean;
}

export class SickNoteExtras {
  image_url: string;
  location: Location;
  metadata: Metadata;
  person: Person;
  status_reasons: StatusReason[];
  type: Type;
  document_type: DocumentType;
}

/* SickNoteDetails *******************************************************************************/
export class Alerts {
  is_late: boolean;
}

export class FieldStatus {
  appointment_date: string;
  expiration_date: string;
  icds: string;
  image_path: string;
  link: string;
  notes: string;
  person_id: string;
  premises: string;
  professional_id: string;
  professional_name: string;
  professional_state: string;
  professional_type: string;
  secret: string;
  type_id: string;
}

export class Issues {
  '<key>': string;
}

export class SickNoteDetails {
  alerts: Alerts;
  field_status: FieldStatus;
  issues: Issues;
}

/* SickNoteFields ********************************************************************************/
export class PublicNotes {
  message: string;
  reasons: string[];
}

export class Icd {
  description: string;
  icd: string;
  ntep: boolean;
}

export class Professional {
  id: string;
  name: string;
  state: string;
  type: string;
}

export class TravelMinutes {
  after: number;
  before: number;
}

export class Vaccine {
  dose: number;
  name: string;
}

export class SickNoteFile {
  id: string;
  name: string;
  path: string;
  is_clean: boolean;
  bucket: string;
  signed_url: string;
  metadata: {
    updated_at: string;
    content_type: string;
  };
}

export class SickNoteFields {
  appointment_date: string;
  public_notes: PublicNotes;
  expiration_date: string;
  icds: Icd[];
  image_path: string;
  link: string;
  person_id: string;
  premises: string;
  professional: Professional;
  secret: string;
  travel_minutes: TravelMinutes;
  type_id: number;
  vaccine: Vaccine;
  files: SickNoteFile[];
}

/* SickNoteApiResponse ***************************************************************************/
export class SickNoteApiResponse {
  company_id: string;
  extras: SickNoteExtras;
  details: SickNoteDetails;
  fields: SickNoteFields;
  short_id: string;
  status: string;
}

class SickNoteSpecific extends SickNoteApiResponse { }

class OccupationalSpecific {
  coordinator_physician: {
    id: number;
    code: number;
    name: string;
    state: string;
  };
  diagnostic_procedures: {
    id: number;
    code: string;
    label: string;
    issue_date: string;
  }[];
  in_charge_physician: {
    code: number;
    state: string;
    name: string;
  };
  result: string;
}

class DocumentFile {
  bucket: string;
  id: string;
  is_clean: any;
  metadata: {
    created_at: string;
    content_type: string;
    updated_at: string;
  };
  name: string;
  path: string;
  signed_url: string;
}

/* Document **************************************************************************************/
export class Document {
  created_at: string;
  email: string;
  external_id: string;
  files: DocumentFile[];
  id: number;
  internal_notes: string;
  issue_date: string;
  organization_type: {
    code: string;
    deleted: boolean;
    description: string;
    id: number;
    label: string;
    organization_id: number;
    type: {
      category: {
        created_at: string;
        id: number;
        label: string;
        updated_at: string;
      };
      created_at: string;
      id: number;
      label: string;
      social_security: boolean;
      updated_at: string;
    };
    visible_b2b: boolean;
    visible_b2c: boolean;
  };
  person_id: number;
  phone: string;
  public_notes: string;
  reasons: DocumentReason[];
  reason_ids: number[];
  short_id: string;
  specific: any; // SickNoteSpecific | OccupationalSpecific;
  status: string;
  sync: {
    code: string;
    message: string;
    status: string;
  };
  team_id: number;
  updated_at: string;
  verified: boolean;
}

export const listReasons = (document: Document): string[] => {
  const reasonsLabels: string[] = [];

  // Previous code that built reasonsLabels for sick notes ***************************************
  if ('fields' in document.specific && document.specific.fields.public_notes) {
    const publicNotes = document.specific.fields.public_notes;
    const availableReasons: any = REASONS;
    if (publicNotes?.message) {
      reasonsLabels.push(publicNotes.message);
    }
    if (publicNotes?.reasons) {
      const reasons = publicNotes.reasons
        .filter(r => !!availableReasons[r])
        ?.map(r => availableReasons[r]);
      reasonsLabels.push(...reasons);
    }
  }
  if ('extras' in document.specific && document.specific.extras.status_reasons) {
    const statusReasons = document.specific.extras.status_reasons
      .filter(s => s.status === document.status && s.public)
      .map(s => s.description);
    reasonsLabels.push(...statusReasons);
  }
  // *********************************************************************************************

  // New code that builds reasonsLabels for new documents ****************************************
  if (document.status === DocumentStatus.VALID || document.status === DocumentStatus.INVALID) {
    if ('public_notes' in document) {
      reasonsLabels.push(document.public_notes);
    }
  }
  // *********************************************************************************************

  return reasonsLabels;
};

export const getDocumentIcon = (status: string): { svgIcon?: string; icon?: string } => {
  switch (status) {
    case DocumentStatus.VALID:
      return { icon: 'thumb_up' };
    case DocumentStatus.INVALID:
      return { icon: 'thumb_down' };
    case DocumentStatus.TRIAGE:
      return { svgIcon: 'stethoscope' };
    default:
      return { icon: 'schedule' };
  }
};
