import {
  Component, Inject
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PersonDependentsService } from 'src/app/services/person-dependents.service';
import { ChildcareDependent } from '../childcare.entities';

@Component({
  selector: 'app-childcare-dependent-dialog',
  templateUrl: './childcare-dependent-dialog.component.html',
  styleUrls: ['./childcare-dependent-dialog.component.scss']
})
export class ChildcareDependentDialogComponent {
  formControl: FormControl;
  dependents: ChildcareDependent[];
  loading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChildcareDependentDialogData,
    public dialogRef: MatDialogRef<ChildcareDependentDialogComponent>,
    private fb: FormBuilder,
    private personDependentsService: PersonDependentsService,
  ) {
    this.formControl = fb.control(null);

    this.loading = true;
    this.personDependentsService.getPersonDependents()
      .subscribe((response) => {
        this.dependents = [
          ...response['items'],
          {
            id: '-1',
            name: 'Nenhum dos acima'
          }
        ];
        this.loading = false;
      });
  }

  select() {
    this.dialogRef.close(this.formControl.value);
  }
}

class ChildcareDependentDialogData {
  title: string;
  button: string;
}
