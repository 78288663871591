import { Injectable } from '@angular/core';
import {
  API_PERSON_DEPENDENTS_LIST,
  ApiService
} from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PersonDependentsService {
  constructor(private api: ApiService) {}

  getPersonDependents() {
    return this.api.http.get(API_PERSON_DEPENDENTS_LIST);
  }
}
