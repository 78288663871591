<div data-test="content" class="container">
  <div class="padding-workaround">
    <div class="content">
      <div [innerHTML]="contentBody"></div>
      <ng-container *ngIf="type !== 'complete'">
        <a *ngIf="!!term?.content?.complete" (click)="showCompleteRules()" class="complete-link">
          Acessar regras completas
        </a>
        <div class="checkbox-container" fxLayout="column" fxLayoutAlign="space-around start" fxLayoutGap="16px">
          <mat-checkbox data-test="checks" *ngFor="let c of termControls.controls; let i = index"
            [formControl]="c" color="primary" [checked]="c.value">
            {{term?.conditions[i]}}
          </mat-checkbox>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="button-container">
    <button
      mat-raised-button
      data-test="submit"
      class="closecare-button primary-bg"
      [disabled]="termControls.invalid"
      [attr.aria-label]="buttonLabel"
      (click)="saveOrClose()"
    >
      {{buttonLabel}}
    </button>
  </div>
</div>
